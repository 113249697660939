.countdown {
    position: relative;
    height: 4px;
    width: 100%;
    background-color: #f3f3f3;
}

.countdown:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: hsl(var(--primary));
    opacity: 0.5;
    animation: counting_down 60s linear forwards;
}

@keyframes counting_down {
    0% {
        left: 0;
    }
    100% {
        left: 100%;
    }
}
