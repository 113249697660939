.loader {
  position: relative;
  height: 4px;
  width: 100%;
  background-color: #f3f3f3;
}

.loader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: hsl(var(--primary));
  opacity: 0.5;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    left: 0;
    right: 0;
  }
  50% {
    left: 50%;
    right: 50%;
  }
  100% {
    left: 0;
    right: 0;
  }
}